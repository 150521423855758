import React, { lazy } from 'react'
import { Button } from "react-bootstrap"
import DropFile from './DropFile'
import Select from "react-select"
import { colourOptions } from "./data"
import "react-dropzone-uploader/dist/styles.css"
import Drop from "react-dropzone-uploader"

const createFormData = (photo, body = {}) => {
  const data = new FormData()

  data.append('photo', photo)
  
  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data
}

const handleSubmit = (files) => {
  console.log(files.map((f) => f.meta))
}

export default class AddDish extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories_list: [
        { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
        { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
        { value: "purple", label: "Purple", color: "#5243AA" },
        { value: "red", label: "Red", color: "#FF5630", isFixed: true },
        { value: "orange", label: "Orange", color: "#FF8B00" },
        { value: "yellow", label: "Yellow", color: "#FFC400" },
        { value: "green", label: "Green", color: "#36B37E" },
        { value: "forest", label: "Forest", color: "#00875A" },
        { value: "slate", label: "Slate", color: "#253858" },
        { value: "silver", label: "Silver", color: "#666666" },
      ],
      categories: [
        {
          category_selected: 0,
          category_name: '',
          options: [
            { title: '', price: '' }
          ]
        }
      ],
      dish_categories_list: [
        { index: 0, value: '', label: '' }
      ],
      dish_categories: [
        {
          category_selected: 0,
          category_name: ''
        }
      ],
      dish_infos: {
        category: '',
        title: '',
        price: '',
        description: ''
      },
      image_file: undefined
    }
  }

  componentDidMount() {
    this.Categories_List()
    this.Dish_Categories_List()
  }

  Categories_List = async () => {
    let fet = await fetch('https://yassbk.com/food/side_dish_sections', {
      method: "POST",
      body: JSON.stringify({
        id_place: this.props.idPlace
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    let categories_list = []
    for (let i = 0; i < rep.length; i++) {
      categories_list.push({
        index: i,
        value: rep[i].section_side_dishes,
        label: rep[i].section_side_dishes
      })
      console.log({
        index: i,
        value: rep[i].section_side_dishes,
        label: rep[i].section_side_dishes
      })
    }
    this.setState({categories_list})
  }

  Dish_Categories_List = async () => {
    let fet = await fetch('https://yassbk.com/food/dish_sections', {
      method: "POST",
      body: JSON.stringify({
        id_place: this.props.idPlace
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    let dish_categories_list = []
    for (let i = 0; i < rep.length; i++) {
      dish_categories_list.push({
        index: i,
        value: rep[i].section_dish,
        label: rep[i].section_dish
      })
      console.log({
        index: i,
        value: rep[i].section_dish,
        label: rep[i].section_dish
      })
      if (rep[i].section_dish == this.state.dish_infos.category) {
        this.state.dish_categories.category_selected = i
        this.setState({dish_categories: this.state.dish_categories})
      }
    }
    this.setState({dish_categories_list})
  }

  Add_A_Category = () => {
    this.state.categories.push({
      category_selected: 0,
      options: [
        { title: '', price: '' }
      ]
    })
    this.setState({ categories: this.state.categories })
  }

  Add_An_Option = (n) => {
    this.state.categories[n].options.push({ title: '', price: '' })
    this.setState({ categories: this.state.categories })
  }

  Upload_Image = (id_dish) => {
    fetch(`https://yassbk.com/food/add_dish_image`, {
      method: 'POST',
      body: createFormData(this.state.image_file, {id_dish}),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('response', response);
        if (response.success) window.location = `http://localhost:3001/dish-details?dish=${id_dish}`
      })
      .catch((error) => {
        console.log('error', error);
      })
  }

  handleChangeStatus = ({ meta, file }, status) => {
    console.log('handleChangeStatus => ', status, meta, file)
    this.setState({image_file: file})
  }

  Submit = async () => {
    let fet = await fetch('https://yassbk.com/food/add_dish', {
      method: "POST",
      body: JSON.stringify({
        dish_infos: this.state.dish_infos,
        categories: this.state.categories,
        id_place: this.props.idPlace
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    if (rep.success) {
      if (this.state.image_file) {
        this.Upload_Image(rep.last_id)
      } else {
        window.location = `${window.location.origin}${window.location.pathname != '' ? window.location.pathname : '/'}#/dish-details?dish=${rep.last_id}`
      }
    }
  }

  render() {
    const CustomClearText = () => "effacer"
    const ClearIndicator = (props) => {
      const {
        children = <CustomClearText />,
        getStyles,
        innerProps: { ref, ...restInnerProps },
      } = props
      return (
        <div
          {...restInnerProps}
          ref={ref}
          style={getStyles("clearIndicator", props)}
        >
          <div style={{ padding: "0px 5px" }}>{children}</div>
        </div>
      )
    }

    const ClearIndicatorStyles = (base, state) => ({
      ...base,
      cursor: "pointer",
      color: state.isFocused ? "blue" : "black",
    })

    const handleKeyPress = (e, n) => {
      if (e.key === 'Enter') {
        console.log(e.target.value, n)
        this.state.categories_list.push({ index: this.state.categories_list.length, value: e.target.value, label: e.target.value })
        this.state.categories[n].category_selected = this.state.categories_list.length - 1
        this.state.categories[n].category_name = e.target.value
        this.setState({ categories_list: this.state.categories_list, categories: this.state.categories })
      }
    }

    const handleSelectOption = (e, n) => {
      console.log(e, e.value, n)
      this.state.categories[n].category_selected = parseInt(e.index)
      this.state.categories[n].category_name = e.value
      this.setState({ categories: this.state.categories })
    }

    const SideDishCategorySelect = (props) => (
      <Select
        closeMenuOnSelect={true}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        defaultValue={this.state.categories_list[this.state.categories[props.n].category_selected]}
        options={this.state.categories_list}
        placeholder='Catégorie du plat'
        noOptionsMessage={() => 'Nouvelle catégorie'}
        onKeyDown={(e) => handleKeyPress(e, props.n)}
        onChange={(e) => handleSelectOption(e, props.n)}
      />
    )

    const handleKeyPress2 = (e, n) => {
      if (e.key === 'Enter') {
        console.log(e.target.value, n)
        this.state.dish_categories_list.push({ index: this.state.dish_categories_list.length, value: e.target.value, label: e.target.value })
        this.state.dish_categories.category_selected = this.state.dish_categories_list.length - 1
        this.state.dish_categories.category_name = e.target.value
        this.state.dish_infos.category = e.target.value
        this.setState({ dish_categories_list: this.state.dish_categories_list, dish_categories: this.state.dish_categories })
      }
    }

    const handleSelectOption2 = (e, n) => {
      console.log(e, e.value, n)
      this.state.dish_categories.category_selected = parseInt(e.index)
      this.state.dish_categories.category_name = e.value
      this.state.dish_infos.category = e.value
      this.setState({ dish_categories: this.state.dish_categories })
    }

    const DishCategorySelect = (props) => (
      <Select
        closeMenuOnSelect={true}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        defaultValue={this.state.dish_categories_list[this.state.dish_categories.category_selected]}
        options={this.state.dish_categories_list}
        placeholder='Catégorie du plat'
        noOptionsMessage={() => 'Nouvelle catégorie'}
        onKeyDown={(e) => handleKeyPress2(e, props.n)}
        onChange={(e) => handleSelectOption2(e, props.n)}
      />
    )
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='card'>
            <div className="card-header">
              <div className="form-group col-md-12">
                <DishCategorySelect n={this.state.dish_categories.category_selected}></DishCategorySelect>
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Catégorie du plat"
                  value={this.state.dish_infos.category}
                  onChange={e => {
                    this.state.dish_infos.category = e.target.value
                    this.setState({dish_infos: this.state.dish_infos})
                  }}
                /> */}
              </div>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-3'>
                  <Drop
                    // getUploadParams={getUploadParams}
                    onChangeStatus={this.handleChangeStatus}
                    onSubmit={handleSubmit}
                    multiple={false}
                    PreviewComponent={(p) => {
                      //console.log(p)
                      //p.files = p.files.length > 1 ?  p.files.slice(1, 2) : p.files
                      let p_class = document.querySelectorAll(`.${p.className}`)
                      if (p_class.length > 1) {
                        for (let i = 0; i < p_class.length; i++) {
                          if (i != (p_class.length - 1)) p_class[i].style.display = 'none'
                        }
                      }
                      return (
                        <div className={p.className}>
                          <img className={p.imageClassName} src={p.files[p.files.length - 1].meta.previewUrl}></img>
                        </div>
                      )
                    }}
                    inputContent="Faites glisser une image ici"
                    //InputComponent={() => <p></p>}
                    inputWithFilesContent="Modifier l'image"
                    SubmitButtonComponent={() => <p></p>}
                    submitButtonDisabled={true}
                    accept="image/*,audio/*,video/*"
                    styles={{
                      dropzone: {
                        minHeight: 200,
                        maxHeight: 250,
                        width: "100%",
                        backgroundColor: "#f2f4fa",
                        border: "1px dashed #DDDFE1",
                        overflow: "hidden",
                      },
                      inputLabel: {
                        color: "#7e7e7e",
                        fontSize: "18px",
                        fontWeight: "normal",
                        backgroundColor: "#f2f4fa",
                      },
                    }}
                  />
                </div>
                <div className="col-md-9">
                  <div className="card">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="form-row">
                            <div className="form-group col-md-9">
                              <label>Titre</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.dish_infos.title}
                                onChange={e => {
                                  this.state.dish_infos.title = e.target.value
                                  this.setState({dish_infos: this.state.dish_infos})
                                }}
                              />
                            </div>
                            <div className="form-group col-md-3">
                              <label>Prix</label>
                              <div className="input-group">
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  value={this.state.dish_infos.price}
                                  onChange={e => {
                                    this.state.dish_infos.price = e.target.value
                                    this.setState({dish_infos: this.state.dish_infos})
                                  }}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">€</span>
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-md-12">
                              <label>Description</label>
                              <input 
                                type="text" 
                                className="form-control" 
                                value={this.state.dish_infos.description}
                                onChange={e => {
                                  this.state.dish_infos.description = e.target.value
                                  this.setState({dish_infos: this.state.dish_infos})
                                }}
                              />
                            </div>
                            <div className="col-md-12">
                              <label>Options du plat</label>
                            </div>
                            {
                              this.state.categories.map((categories, categoriesIndex) => (
                                <>
                                  <div className='from-group col-md-12' style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 15 }}>
                                    <SideDishCategorySelect n={categoriesIndex}></SideDishCategorySelect>
                                  </div>
                                  {
                                    categories.options.map((option, optionIndex) => (
                                      <div className="form-group col-md-12 d-flex align-items-center">
                                        <div className="col-md-10">
                                          <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder='Titre' 
                                            value={this.state.categories[categoriesIndex].options[optionIndex].title}
                                            onChange={e => {
                                              this.state.categories[categoriesIndex].options[optionIndex].title = e.target.value
                                              this.setState({categories: this.state.categories})
                                            }}
                                          />
                                        </div>
                                        <div className="col-md-2">
                                          <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder='+ 1.00€'
                                            value={this.state.categories[categoriesIndex].options[optionIndex].price}
                                            onChange={e => {
                                              this.state.categories[categoriesIndex].options[optionIndex].price = e.target.value
                                              this.setState({categories: this.state.categories})
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ))
                                  }
                                  <div className="form-group col-md-12 d-flex align-items-center justify-content-center">
                                    <Button variant="outline-primary btn-rounded btn-sm" onClick={() => this.Add_An_Option(categoriesIndex)}>
                                      Ajouter une option
                                    </Button>
                                  </div>
                                </>
                              ))
                            }
                            <div className="form-group col-md-12 d-flex align-items-center justify-content-center">
                              <Button variant="outline-primary btn-rounded btn-md" onClick={this.Add_A_Category}>
                                Ajouter une catégorie d'options
                              </Button>
                            </div>
                          </div>
                          <Button variant="primary btn-rounded" onClick={this.Submit}>
                            <span className="btn-icon-left text-primary">
                              <i className="fa fa-plus color-primary" />
                            </span>
                            Ajouter ce plat
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
