import * as React from 'react'
import Products from '../AppsMenu/Shop/ProductGrid/Products'

export default class DishesList extends React.Component {
  state = {
    dishes_list: []
  }

  componentDidMount() {
    this.Dishes_List()
  }

  Dishes_List = async () => {
    let fet = await fetch('https://yassbk.com/food/dishes_by_place', {
      method: "POST",
      body: JSON.stringify({
        id_place: this.props.idPlace
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    this.setState({dishes_list: rep})
  }

  render() {
    return (
      <div className="row">
      { this.state.dishes_list.length == 0 ? <span style={{marginLeft: 30}}>Aucun plat répertorié.</span> : undefined}
      {
        this.state.dishes_list.map((item) => (
          <Products key={item.id_dish} product={item} />
        ))
      }
      </div>
    )
  }
}