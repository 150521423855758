export const Icon01 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#5FA76F"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="white"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4141 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);

export const Icon02 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#4B58CF"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="#8FD7FF"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4141 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon03 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#40C7CF"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="#8FD7FF"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4141 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon04 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#FF4848"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="#8FD7FF"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4141 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon05 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#FFCBF0"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="#EC16FF"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4141 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="#F364FF"
      />
   </svg>
);
export const Icon06 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D0A96D"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="white"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4141 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon07 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#BB1D85"
      />
      <path
         d="M20.6215 59.0078C18.1011 56.4873 16.1017 53.4951 14.7377 50.202C13.3736 46.9089 12.6715 43.3793 12.6715 39.8149C12.6715 36.2504 13.3736 32.7209 14.7377 29.4277C16.1017 26.1346 18.1011 23.1424 20.6215 20.622C23.1419 18.1015 26.1342 16.1022 29.4273 14.7381C32.7204 13.3741 36.2499 12.672 39.8144 12.672C43.3788 12.672 46.9084 13.3741 50.2015 14.7381C53.4946 16.1022 56.4868 18.1015 59.0073 20.622L49.4108 30.2184C48.1506 28.9582 46.6545 27.9585 45.008 27.2765C43.3614 26.5945 41.5966 26.2434 39.8144 26.2434C38.0322 26.2434 36.2674 26.5945 34.6208 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184C28.9577 31.4786 27.9581 32.9747 27.276 34.6213C26.594 36.2679 26.243 38.0326 26.243 39.8148C26.243 41.5971 26.594 43.3619 27.276 45.0084C27.9581 46.655 28.9577 48.1511 30.2179 49.4113L20.6215 59.0078Z"
         fill="white"
      />
      <path
         d="M59.0074 59.0077C53.9171 64.098 47.0132 66.9577 39.8145 66.9577C32.6158 66.9577 25.7119 64.098 20.6216 59.0078C15.5313 53.9175 12.6717 47.0136 12.6717 39.8149C12.6716 32.6161 15.5313 25.7122 20.6216 20.622L30.2181 30.2184C27.6729 32.7635 26.2431 36.2155 26.2431 39.8149C26.2431 43.4142 27.6729 46.8662 30.2181 49.4113C32.7632 51.9564 36.2151 53.3863 39.8145 53.3863C43.4139 53.3863 46.8658 51.9564 49.411 49.4113L59.0074 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon08 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#4B58CF"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="#8FD7FF"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4141 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon09 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#F0780A"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.655 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="white"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4142 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon10 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#515151"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.655 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="#515151"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4142 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon11 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#5D3387"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.655 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="#8E49D2"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4142 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
export const Icon12 = (
   <svg
      className="mb-4"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
         fill="#31B52F"
      />
      <path
         d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.655 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
         fill="white"
      />
      <path
         d="M20.6216 59.0077C15.5313 53.9174 12.6716 47.0135 12.6716 39.8148C12.6716 32.6161 15.5313 25.7122 20.6215 20.6219C25.7118 15.5316 32.6157 12.6719 39.8144 12.6719C47.0132 12.6719 53.9171 15.5316 59.0073 20.6219L49.4109 30.2183C46.8658 27.6732 43.4138 26.2434 39.8144 26.2434C36.2151 26.2434 32.7631 27.6732 30.218 30.2183C27.6729 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4142 27.6729 46.8661 30.218 49.4112L20.6216 59.0077Z"
         fill="white"
      />
   </svg>
);
