import * as React from 'react'
import { Link } from "react-router-dom";

export default class DishDetails extends React.Component {
  state = {
    dish_details: {},
    side_dishes_sections: []
  }

  componentDidMount() {
    const dish = window.location.hash.replace( /^\D+/g, '')
    this.Side_Dishes_List(1, dish)
    this.Dish_Details(dish)
  }

  Dish_Details = async (id_dish) => {
    let fet = await fetch('https://yassbk.com/food/dish_details', {
      method: "POST",
      body: JSON.stringify({
        id_dish
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    this.setState({ dish_details: rep })
  }

  Side_Dishes_List = async (id_place, id_dish) => {
    let fet = await fetch('https://yassbk.com/food/side_dishes_by_dish', {
      method: "POST",
      body: JSON.stringify({
        id_place,
        id_dish
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    let dish_sections = []
    let dish_sections_title = {}
    let z = 0
    for (let i = 0; i < rep.length; i++) {
      let dish_sections_exists = false
      for (let j = 0; j < dish_sections.length; j++) {
        if (rep[i].section_side_dishes == dish_sections[j].title) {
          dish_sections_exists = true
          break
        }
      }
      if (i == 0) {
        dish_sections.push({
          title: rep[i].section_side_dishes,
          type: rep[i].type_side_dishes,
          data: []
        })
        dish_sections_title[rep[i].section_side_dishes] = z
        z++
      } else if ((rep[i].section_side_dishes != rep[i-1].section_side_dishes) && !dish_sections_exists) { 
        dish_sections.push({
          title: rep[i].section_side_dishes,
          type: rep[i].type_side_dishes,
          data: []
        })
        dish_sections_title[rep[i].section_side_dishes] = z
        z++
      }
    }
    for (let i = 0; i < rep.length; i++) {
        dish_sections[dish_sections_title[rep[i].section_side_dishes]].data.push({
          id: rep[i].id_side_dishes,
          title: rep[i].name_side_dishes,
          price: rep[i].price_side_dishes,
          section_dish: rep[i].section_side_dishes,
          status_dish: rep[i].status_side_dishes,
        })
    }
    console.log(typeof(dish_sections))
    console.log(dish_sections)
    this.setState({side_dishes_sections: dish_sections})
  }

  Change_Availability_Side_Dish = async (id_side_dishes) => {
    //console.log(id_side_dishes)
    let fet = await fetch('https://yassbk.com/food/change_availability_side_dish', {
      method: "POST",
      body: JSON.stringify({
        id_side_dishes
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    const dish = window.location.hash.replace( /^\D+/g, '')
    if (rep) this.Side_Dishes_List(1, dish)
  }

  render() {
    return (
      <div className="row">
      {
        this.state.dish_details != {}
        ?
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-xl-3 ">
                  <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade show active" id="first" style={{borderRadius: '0.75rem'}}>
                      <img className="img-fluid" style={{borderRadius: '0.75rem'}} src={this.state.dish_details.img_dish} alt="" />
                    </div>
                    {/* <div role="tabpanel" className="tab-pane fade" id="second">
                      <img className="img-fluid" src={require("../../../images/product/2.jpg").default} alt="" />
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="third">
                      <img className="img-fluid" src={require("../../../images/product/3.jpg").default} alt="" />
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="for">
                      <img className="img-fluid" src={require("../../../images/product/4.jpg").default} alt="" />
                    </div> */}
                  </div>
                  <div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
                    {/* <ul className="nav slide-item-list mt-3" role="tablist">
                      <li role="presentation" className="show">
                        <a href="#first" role="tab" data-toggle="tab">
                          <img className="img-fluid" src={require("../../../images/tab/1.jpg").default} alt="" width="50" />
                        </a>
                      </li>
                      <li role="presentation">
                        <a href="#second" role="tab" data-toggle="tab"><img className="img-fluid" src={require("../../../images/tab/2.jpg").default} alt="" width="50" /></a>
                      </li>
                      <li role="presentation">
                        <a href="#third" role="tab" data-toggle="tab"><img className="img-fluid" src={require("../../../images/tab/3.jpg").default} alt="" width="50" /></a>
                      </li>
                      <li role="presentation">
                        <a href="#for" role="tab" data-toggle="tab"><img className="img-fluid" src={require("../../../images/tab/4.jpg").default} alt="" width="50" /></a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                  <div className="product-detail-content">
                    <div className="new-arrival-content pr">
                      <h4 >{this.state.dish_details.title_dish}</h4>
                      {/* <div className="star-rating d-inline mb-2">
                        {rating}
                        <span className="review-text"> (34 reviews) / </span>
                        <Link className="product-review" to="#">
                          Write a review?
                        </Link>
                      </div> */}
                      <p className="price">{parseFloat(this.state.dish_details.price_dish).toFixed(2)}€</p>
                      {/* <p>
                        Availability:
                        <span className="item">
                          {availability}
                          <i className="fa fa-shopping-basket"></i>
                        </span>
                      </p> */}
                      {/* <p>
                        Product code:
                        <span className="item">{productCode}</span>
                      </p> */}
                      {/* <p>
                        Brand: <span className="item">{brand}</span>
                      </p> */}
                      <p>
                        {/* Section:&nbsp;&nbsp; */}
                        <span
                          className="badge badge-success light mr-1"
                        >
                          {this.state.dish_details.section_dish}
                        </span>
                        {/* {tags.map((tag, i) => (
                          <span
                            className="badge badge-success light mr-1"
                            key={i}
                          >
                            {tag}
                          </span>
                        ))} */}
                      </p>
                      <p className="text-content">{this.state.dish_details.description_dish}</p>
                      <div className="filtaring-area my-3">
                      {this.state.side_dishes_sections.map((section, sectionIndex) => (
                        <div className="size-filter" key={sectionIndex}>
                          <h4 className="m-b-15">{section.title}</h4>
                          <div
                            className="btn-group d-flex flex-column"
                            data-toggle="buttons"
                          >
                          {section.data.map((dish, dishIndex) => (
                              <span onClick={() => this.Change_Availability_Side_Dish(dish.id)} className={`btn ${dish.status_dish ? 'btn-outline-primary' : 'btn-outline-dark'} light btn-sm d-flex align-items-center`} style={{borderRadius: '0.75rem', marginBottom: 8}} key={dishIndex}>
                                <input
                                  type="radio"
                                  className="position-absolute invisible"
                                  name="options"
                                  id="option5"
                                />
                                {dish.title} {dish.price > 0 ? <span style={{fontSize: 10, color: 'grey', marginLeft: 15}}>+ {dish.price.toFixed(2)}€</span> : ''}
                              </span>
                            ))
                          }
                          </div>
                        </div>
                      ))}
                      </div>
                      {/* <div className="col-2 px-0">
                        <input
                          type="number"
                          name="num"
                          className="form-control input-btn input-number"
                          defaultValue="1"
                        />
                      </div> */}
                      <div className="shopping-cart mt-3">
                        <Link
                          className="btn btn-primary btn-lg"
                          to={() => {
                            const dish = window.location.hash.replace( /^\D+/g, '')
                            return `/edit-dish?dish=${dish}`
                          }}
                        >
                          {/* <i className="fa fa-shopping-basket mr-2"></i> */}
                          Modifier
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <p>Loading...</p>
      }
      </div>
    )
  }
}