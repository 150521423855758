import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import Dropdownblog1 from './Dropdownblog1';
import {Dropdown} from 'react-bootstrap';
import moment from 'moment'
import 'moment/locale/fr'

moment.locale('fr')

const DropDownDOM = (props) => (
  <Dropdown>
    <Dropdown.Toggle   variant	className="table-dropdown icon-false" >
      <svg width="24px" height="24px"	viewBox="0 0 24 24" version="1.1">
        <g stroke="none"	strokeWidth="1"	fill="none"	fillRule="evenodd" >
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle	fill="#000000"	cx="5"	cy="12"	r="2"></circle>
          <circle	fill="#000000"	cx="12"	cy="12"	r="2"></circle>
          <circle	fill="#000000"	cx="19"	cy="12"	r="2"></circle>
        </g>
      </svg>
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.Item > 	
        <Link to={`/app-profile?user=${props.id_user}`} className="text-black" >
          <svg className="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M12 16V12" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M12 8H12.01" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          View Details
        </Link>
      </Dropdown.Item>
      <Dropdown.Item  href="#"> 
        <Link to={""} className="text-black" >
          <svg className="mr-3" width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><path d="m3 7h2v20.48a3.53 3.53 0 0 0 3.52 3.52h15a3.53 3.53 0 0 0 3.48-3.52v-20.48h2a1 1 0 0 0 0-2h-26a1 1 0 0 0 0 2zm22 0v20.48a1.52 1.52 0 0 1 -1.52 1.52h-15a1.52 1.52 0 0 1 -1.48-1.52v-20.48z"/><path d="m12 3h8a1 1 0 0 0 0-2h-8a1 1 0 0 0 0 2z"/><path d="m12.68 25a1 1 0 0 0 1-1v-12a1 1 0 0 0 -2 0v12a1 1 0 0 0 1 1z"/><path d="m19.32 25a1 1 0 0 0 1-1v-12a1 1 0 0 0 -2 0v12a1 1 0 0 0 1 1z"/></g></svg>
          Delete
        </Link>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
)
	
class Customerlist extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users_list: {
        columns: [
          {label: 'Order ID', field: 'id', sort: 'asc',  width: 100},
          {label: 'date', field: 'date',   sort: 'asc', },
          {label: 'Customer Name', field: 'customer',	sort: 'asc', 	},
          {label: 'Location', field: 'location', sort: 'asc', 	},
          {label: 'Total Spent', field: 'amount', sort: 'asc', 	},
          {label: 'Last Order', field: 'status',  sort: 'asc',	},
          {label: 'Action', field: 'dropdown',sort: 'asc',	},
          ],	
          rows: []
      }
    }
  }

  componentDidMount() {
    this.Users_List()
  }

  Users_List = async () => {
    let fet = await fetch('https://yassbk.com/food/customers_list', {
      method: "POST",
      body: JSON.stringify({
        id_place: this.props.idPlace
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    let users_list = {
      columns: [
        {label: 'Order ID', field: 'id', sort: 'asc',  width: 100},
        {label: 'Date', field: 'date',   sort: 'asc', },
        {label: 'Customer Name', field: 'customer',	sort: 'asc', 	},
        {label: 'Location', field: 'location', sort: 'asc', 	},
        {label: 'Total Spent', field: 'amount', sort: 'asc', 	},
        {label: 'Action', field: 'dropdown',sort: 'asc',	},
        ],	
        rows:[]
    }
    for (let i = 0; i < rep.length; i++) {
      users_list.rows.push({
        id: `#${rep[i].account_id}`,	date: moment(rep[i].account_created_at).format('DD/MM/YYYY HH:mm'),	customer: `${rep[i].firstName_user} ${rep[i].lastName_user}`,	location: rep[i].delivery_address_order,
        amount:<Link to ={"#"} className="btn bgl-light text-black btn-sm">{rep[i].total != null ? rep[i].total.toFixed(2) : 0} €</Link>,
        dropdown: <DropDownDOM id_user={rep[i].id_user} />,
      })
    }
    this.setState({users_list})
  }

	render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <div  className="display mb-4 dataTablesCard customer-list-table">		
                <MDBDataTable  striped 	small	data={this.state.users_list}	/>	
              </div>
            </div>
          </div>	
        </div>
      </Fragment>		
    )
  }
}
export default Customerlist;