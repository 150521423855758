import { RangeDatePicker } from '@y0c/react-datepicker'
import { MDBDataTable } from 'mdbreact';
import "../../index.css";
import React, { lazy } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import { Link } from '@material-ui/core';

moment.locale('fr')

export default class Empty extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      promo_codes_list: {
        columns: [
          {label: 'Code', field: 'code', sort: 'asc',  width: 100},
          {label: 'Période', field: 'period',   sort: 'asc', },
          {label: 'Remise', field: 'discount',	sort: 'asc', 	}
          ],	
          rows: []
      },
      startDate: new Date(),
      endDate: new Date(),
      code: '',
      discount: ''
    }
  }

  componentDidMount() {
    console.log(window.location)
    this.Promo_Codes_List()
  }

  Promo_Codes_List = async () => {
    let fet = await fetch('https://yassbk.com/food/promo_codes_list')
    let rep = await fet.json()
    //console.log(rep)
    let promo_codes_list = {
      columns: [
        {label: 'Code', field: 'code', sort: 'asc',  width: 100},
        {label: 'Période', field: 'period',   sort: 'asc', },
        {label: 'Remise', field: 'discount',	sort: 'asc', 	}
        ],	
        rows: []
    }
    for (let i = 0; i < rep.length; i++) {
      promo_codes_list.rows.push({
        code: rep[i].code_promo_code,
        period: `${moment(rep[i].start_promo_code).format('DD/MM/YYYY')} au ${moment(rep[i].end_promo_code).format('DD/MM/YYYY')}`,
        discount: <Link to ={"#"} className="btn bgl-light text-black btn-sm">{rep[i].discount_promo_code != null ? rep[i].discount_promo_code.toFixed(2) : 0} €</Link>,
      })
    }
    console.log(promo_codes_list)
    this.setState({ promo_codes_list })
  }

  Add_Promo_Code = async () => {
    let fet = await fetch('https://yassbk.com/food/add_promo_code', {
      method: "POST",
      body: JSON.stringify({
        code: this.state.code,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        discount: this.state.discount
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    if (rep) window.location.reload(true)
  }

  render() {
    return (
      <div className='row' style={{ height: window.innerHeight - 120 }}>
        <div className="form-row col-md-12">
          <div className="form-group col-md-3">
            <label>Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="PROMO"
              onChange={e => this.setState({code: e.target.value})}
            />
          </div>
          <div className="form-group col-md-5">
            <label>Email</label>
            <br />
            <RangeDatePicker
              startText="Début"
              endText="Fin"
              startPlaceholder="Date de début"
              endPlaceholder="Date de fin"
              dateFormat='DD/MM/YYYY'
              onChange={(start, end) => this.setState({startDate: start, endDate: end})}
            />
          </div>
          <div className="form-group col-md-2">
            <label>Remise</label>
            <input
              type="text"
              className="form-control"
              placeholder="0.00"
              onChange={e => this.setState({discount: e.target.value})}
            />
          </div>
          <div className="form-group col-md-1">
            <label style={{color: 'transparent'}}>Valider</label>
            <br />
            <button type="submit" onClick={this.Add_Promo_Code} className="btn btn-primary">
              Ajouter
            </button>
          </div>
        </div>	
        <MDBDataTable className='col-md-8' striped	data={this.state.promo_codes_list} searching={false} displayEntries={false} info={false} paginationLabel={['Précédent', 'Suivant']} />
      </div>
    )
  }
}
