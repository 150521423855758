import React from "react";
import { Link } from "react-router-dom";
import transparent from '../../../Dishes/nada.png'
import '../../../../index.css'

const Products = ({ product: { id_dish, img_dish, title_dish, description_dish, price_dish } }) => {
   return (
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
         <div className="card">
            <div className="card-body">
               <div className="new-arrival-product">
                  <div className="new-arrivals-img-contnent">
                     <img className="img-fluid" src={img_dish != '' ? img_dish : transparent} alt="" />
                  </div>
                  <div className="new-arrival-content text-center mt-3" style={{display: 'flex', flexDirection: 'column'}}>
                     <h4>
                        <Link to={`/dish-details?dish=${id_dish}`} className="text-black">{title_dish}</Link>
                     </h4>
                     <span className='dish_description' style={{width: '100%', fontSize: '0.9rem'}}>{description_dish}</span>
                     {/* {rating} */}
                     <span className="price">{price_dish.toFixed(2)} €</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Products;
