import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import Dropdownblog2 from './Dropdownblog2';
import moment from 'moment'
import 'moment/locale/fr'
import {Dropdown} from 'react-bootstrap';

moment.locale('fr')

const DropDownDOM = (props) => (
  <Fragment>
    <Dropdown>
      <Dropdown.Toggle   variant	className="table-dropdown icon-false" >
        <svg width="24px" height="24px"	viewBox="0 0 24 24" version="1.1">
          <g stroke="none"	strokeWidth="1"	fill="none"	fillRule="evenodd" >
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle	fill="#000000"	cx="5"	cy="12"	r="2"></circle>
            <circle	fill="#000000"	cx="12"	cy="12"	r="2"></circle>
            <circle	fill="#000000"	cx="19"	cy="12"	r="2"></circle>
          </g>
        </svg>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item > 	
          <Link to={`/order?order=${props.id_order}`} className="text-black" >
            <svg className="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M12 16V12" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M12 8H12.01" stroke="#6F6F6F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            View Details
          </Link>
        </Dropdown.Item>
        <Dropdown.Item href="#"> 
          <Link to ={""} className="text-black" >
            <svg className="mr-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#F24242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M15 9L9 15" stroke="#F24242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M9 9L15 15" stroke="#F24242" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            Cancel order
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>	
  </Fragment>
)

const Status = (status) => {
  switch (status) {
    case (0):
      return <Link className="btn bgl-info text-info btn-sm">Commande placée</Link>
    case (1):
      return <Link className="btn bgl-info text-info btn-sm">En cours de préparation</Link>
    case (2):
      return <Link className="btn bgl-info text-info btn-sm">En attente de récupération</Link>
    case (3):
      return <Link className="btn bgl-success text-success btn-sm">Commande terminée</Link>
  }
}

class DatatablePstatus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orders_list: {
        column: [
          {label: 'Order ID', field: 'id', sort: 'asc',  width: 100},
          {label: 'date', field: 'date',   sort: 'asc', },
          {label: 'Customer Name', field: 'customer',	sort: 'asc', 	},
          {label: 'Location', field: 'location', sort: 'asc', 	},
          {label: 'Amount', field: 'amount', sort: 'asc', 	},
          {label: 'Status', field: 'status',  sort: 'asc',	},
          {label: 'Action', field: 'dropdown',sort: 'asc',	},
        ],
        rows: []
      },
      orders_list_fetched: false
    }
  }

  componentDidMount() {
    console.log(this.props)
    this.Orders_History()
  }

  Orders_History = async () => {
    let fet = await fetch('https://yassbk.com/food/orders_history', {
      method: "POST",
      body: JSON.stringify({
        id_place: this.props.idPlace
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    let rep = await fet.json()
    console.log(rep)
    let orders_list = {}
    orders_list['columns'] = [
      {label: 'Order ID', field: 'id', sort: 'asc',  width: 100},
      {label: 'date', field: 'date',   sort: 'asc', },
      {label: 'Customer Name', field: 'customer',	sort: 'asc', 	},
      {label: 'Location', field: 'location', sort: 'asc', 	},
      {label: 'Amount', field: 'amount', sort: 'asc', 	},
      {label: 'Status', field: 'status',  sort: 'asc',	},
      {label: 'Action', field: 'dropdown',sort: 'asc',	},
    ]
    orders_list['rows'] = []
    for (let i = 0; i < rep.length; i++) {
      orders_list.rows.push({
        id: rep[i].number_order,
        customer: rep[i].delivery_name_order,
        location: rep[i].delivery_address_order,
        date: moment(rep[i].created_at).format('DD/MM/YYYY HH:mm'),
        amount: `${parseFloat(rep[i].total_price_order).toFixed(2)} €`,
        status: Status(rep[i].status_order),
        dropdown: <DropDownDOM id_order={rep[i].id_order} />
      })
    }
    this.setState({orders_list, orders_list_fetched: true})
  }

	render() {
    return (
    this.state.orders_list.rows.length > 0
    ?
    <Fragment>
        <div className="row">
          <div className="col-xl-12">
            <div className="table-responsive">
              <div  className="display mb-4 dataTablesCard">					
                <MDBDataTable  striped	small	data={this.state.orders_list}	/>		
              </div>
            </div>
          </div>	
        </div>
      </Fragment>	
      :
      this.state.orders_list_fetched
      ?
      <p>Aucune commande réalisée pour le moment</p>
      :
      <p>Loading...</p>	
    )
  }
}
export default DatatablePstatus;