import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
//** Import Image */
import profile01 from "../../../../images/profile/1.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";

import Lightgallery from './Lightgallery';
import { Dropdown } from "react-bootstrap";

class AppProfile extends React.Component {
   state = {
     activeToggle: 'setting',
     user_details: {},
     email: '',
     address: '',
     complement_address: '',
     city: '',
     zip: ''
   }

   componentDidMount() {
    const user = window.location.hash.replace( /^\D+/g, '')
    this.User_Details(user)
   }
 
   User_Details = async (id_user) => {
     let fet = await fetch('https://yassbk.com/food/user_by_id', {
      method: "POST",
      body: JSON.stringify({
        id_user
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
     let rep = await fet.json()
     console.log(rep)
     this.setState({
       user_details: rep,
       email: rep.mail_user,
       address: rep.main_address,
       complement_address: rep.complement_address,
       city: rep.city_address,
       zip: rep.zip_address
      })
   }

   Update = async () => {
    let fet = await fetch('https://yassbk.com/food/update_user', {
      method: "POST",
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        address: this.state.address,
        complement_address: this.state.complement_address,
        city: this.state.city,
        zip: this.state.zip
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
     let rep = await fet.json()
     console.log(rep)
     if (rep) window.location.reload(true)
   }

   render() {
    return (
      this.state.user_details != {}
      ?
      <Fragment>
        <div className="row">
            <div className="col-lg-12">
               <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                     <div className="profile-info">
                        {/* <div className="profile-photo">
							<img src={profile}	className="img-fluid rounded-circle" alt="profile"	/>
                        </div> */}
                        <div className="profile-details">
							<div className="profile-name px-3 pt-2">
								<h4 className="text-primary mb-0">
									{this.state.user_details.firstName_user} {this.state.user_details.lastName_user}
								</h4>
								<p>Customer</p>
							</div>
							<div className="profile-email px-2 pt-2">
								<h4 className="text-muted mb-0">
                {this.state.user_details.mail_user}
								</h4>
								<p>Email</p>
							</div>
							<Dropdown className="dropdown ml-auto">
								<Dropdown.Toggle
									variant="primary"
									className="btn btn-primary light sharp icon-false"
									data-toggle="dropdown"
									aria-expanded="true"
								>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="18px"
                                    height="18px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                >
                                    <g
                                       stroke="none"
                                       strokeWidth="1"
                                       fill="none"
                                       fillRule="evenodd"
                                    >
                                       <rect x="0" y="0" width="24" height="24"></rect>
                                       <circle  fill="#000000"  cx="5"  cy="12"  r="2" ></circle>
                                       <circle fill="#000000" cx="12" cy="12"  r="2" ></circle>
                                       <circle fill="#000000" cx="19"  cy="12" r="2"  ></circle>
									</g>
                                </svg>
								</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu dropdown-menu-right">
									<Dropdown.Item className="dropdown-item">
										<i className="fa fa-user-circle text-primary mr-2" />
										View profile
									</Dropdown.Item>
									<Dropdown.Item className="dropdown-item">
										<i className="fa fa-users text-primary mr-2" />
										Add to close friends
									</Dropdown.Item>
									<Dropdown.Item className="dropdown-item">
										<i className="fa fa-plus text-primary mr-2" />
										Add to group
									</Dropdown.Item>
									<Dropdown.Item className="dropdown-item">
										<i className="fa fa-ban text-primary mr-2" />
										Block
									</Dropdown.Item>
								</Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
         </div>
         <div className="row">
            <div className="col-12">
               <div className="card">
                  <div className="card-body">
                     <div className="profile-tab">
                        <div className="custom-tab-1">
                           <ul className="nav nav-tabs">
                              <li className="nav-item">
                                 <Link
                                    to="#profile-settings"
                                    data-toggle="tab"
                                    onClick={() => this.setState({activeToggle: "setting"})}
                                    className={`nav-link ${
                                       this.state.activeToggle === "setting"
                                          ? "active show"
                                          : ""
                                    }`}
                                 >
                                    Setting
                                 </Link>
                              </li>
                           </ul>
                           <div className="tab-content">
                             <div
                                 id="profile-settings"
                                 className={`tab-pane fade ${
                                  this.state.activeToggle === "setting"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="pt-3">
                                    <div className="settings-form">
                                       <h4 className="text-primary">
                                          Account Setting
                                       </h4>
                                       <form
                                          onSubmit={(e) => e.preventDefault()}
                                       >
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>Email</label>
                                                <input
                                                   type="email"
                                                   placeholder="Email"
                                                   className="form-control"
                                                   value={this.state.email}
                                                />
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Password</label>
                                                <input
                                                   type="password"
                                                   placeholder="Password"
                                                   className="form-control"
                                                />
                                             </div>
                                          </div>
                                          <div className="form-group">
                                             <label>Address</label>
                                             <input
                                                type="text"
                                                placeholder="1234 Main St"
                                                className="form-control"
                                                value={this.state.address}
                                             />
                                          </div>
                                          <div className="form-group">
                                             <label>Address 2</label>
                                             <input
                                                type="text"
                                                placeholder="Apartment, studio, or floor"
                                                className="form-control"
                                                value={this.state.complement_address}
                                             />
                                          </div>
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>City</label>
                                                <input
                                                   type="text"
                                                   className="form-control"
                                                   value={this.state.city}
                                                />
                                             </div>
                                             {/* <div className="form-group col-md-4">
                                                <label>State</label>
                                                <select
                                                   className="form-control"
                                                   id="inputState"
                                                   defaultValue="option-1"
                                                >
                                                   <option value="option-1">
                                                      Choose...
                                                   </option>
                                                   <option value="option-2">
                                                      Option 1
                                                   </option>
                                                   <option value="option-3">
                                                      Option 2
                                                   </option>
                                                   <option value="option-4">
                                                      Option 3
                                                   </option>
                                                </select>
                                             </div> */}
                                             <div className="form-group col-md-2">
                                                <label>Zip</label>
                                                <input
                                                   type="text"
                                                   className="form-control"
                                                   value={this.state.zip}
                                                />
                                             </div>
                                          </div>
                                          {/* <div className="form-group">
                                             <div className="custom-control custom-checkbox">
                                                <input
                                                   type="checkbox"
                                                   className="custom-control-input"
                                                   id="gridCheck"
                                                />
                                                <label
                                                   className="custom-control-label"
                                                   htmlFor="gridCheck"
                                                >
                                                   Check me out
                                                </label>
                                             </div>
                                          </div> */}
                                          <button
                                             className="btn btn-primary"
                                             type="submit"
                                             onClick={this.Update}
                                          >
                                             Update
                                          </button>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
      :
      <label>Loading...</label>
   );
   }
};

export default AppProfile;
