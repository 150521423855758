import React, { PureComponent } from "react";
import { BarChart, Bar, ResponsiveContainer } from "recharts";

const data = [
   {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
   },
   {
      name: "Page B",
      uv: -3000,
      pv: 1398,
      amt: 2210,
   },
   {
      name: "Page C",
      uv: -2000,
      pv: -3200,
      amt: 2290,
   },
   {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
   },
   {
      name: "Page E",
      uv: -1890,
      pv: 4800,
      amt: 2181,
   },
   {
      name: "Page F",
      uv: 2390,
      pv: -3800,
      amt: 2500,
   },
   {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
   },
];

export default class Example extends PureComponent {
   render() {
      return (
         <ResponsiveContainer
            height={this.props.height ? this.props.height : "100%"}
            width={this.props.width ? this.props.width : "100%"	}
         >
            <BarChart
               data={data}
               margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5,
               }}
            >
               <Bar dataKey="pv" fill="#2fb5dd" />
               <Bar dataKey="uv" fill="#2bc155" />
            </BarChart>
         </ResponsiveContainer>
      );
   }
}
